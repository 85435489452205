<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav d-flex justify-content-center"
  >
    <!-- <template v-if="isGlobalAllowed()">
      <template v-for="item in items">
        
        <component
          v-if="item.globalAllow && item.globalAllow == true"
          :is="resolveNavComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
    <template v-else>
      <template v-for="item in items">
      

        <component
          v-if="!item['onlyVendor']"
          :is="resolveNavComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template> -->

    <template v-for="item in items">
      <component
        v-if="item.globalAllow && isGlobalAllowed()"
        :is="resolveNavComponent(item)"
        :key="item.header || item.title"
        :item="item"
      />

      <component
        v-if="!item['globalAllow'] "
        :is="resolveNavComponent(item)"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return "horizontal-nav-menu-header-group";
      return "horizontal-nav-menu-header-link";
    };

    return {
      resolveNavComponent,
    };
  },
  methods: {
    isVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isGlobalAllowed() {
      if (
        this.$store.state.app.workspaces &&
        this.$store.state.app.workspaces.length > 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

import store from "@/store";
const developmentRoutes = [
  {
    title: "Global Dashboard",
    route: "globaldashboard",
    icon: "PieChartIcon",
    globalAllow:true,
  },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Standards",
    route: "standards",
    icon: "AwardIcon",
  },
  // {
  //   header: 'Frameworks',
  //   icon: 'LayersIcon',
  //   children: [
  //     {
  //       title: 'Standards',
  //       route: 'standards',
  //       icon: 'AwardIcon',
  //     },
  //   ],
  // },

  {
    header: "Registers",
    icon: "EditIcon",
    children: [
      {
        title: "Asset Register",
        route: "assets",
        icon: "ArchiveIcon",
      },

      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Incident Register",
        route: "incidentregister",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Legal Register",
        route: "legalregister",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    header: "Risk Management",
    icon: "GridIcon",
    children: [
      {
        title: "Risk Assessment",
        route: "riskassessment",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    header: "Assessments",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Gap Assessment",
        route: "gapassessment",
        icon: "TrendingUpIcon",
      },
    ],
  },

  {
    title: "Tasks",
    route: "tasks",
    icon: "FilePlusIcon",
  },
  {
    title: "Third Party",
    route: "vendors",
    icon: "FilePlusIcon",
  },

  {
    header: "ISMS",
    icon: "RefreshCwIcon",
    children: [
      {
        title: "Periodic Activities",
        route: "periodicactivities",
        icon: "AlertOctagonIcon",
      },
    ],
  },
];

const productionRoutes = [
  {
    title: "Global Dashboard",
    route: "globaldashboard",
    icon: "PieChartIcon",
    globalAllow:true,
    
  },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Standards",
    route: "standards",
    icon: "AwardIcon",
  },

  {
    header: "Registers",
    icon: "EditIcon",
    children: [
      {
        title: "Asset Register",
        route: "assets",
        icon: "ArchiveIcon",
      },

      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Incident Register",
        route: "incidentregister",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Legal Register",
        route: "legalregister",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    header: "Risk Management",
    icon: "GridIcon",
    children: [
      {
        title: "Risk Assessment",
        route: "riskassessment",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    header: "Assessments",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Gap Assessment",
        route: "gapassessment",
        icon: "TrendingUpIcon",
      },
    ],
  },

  {
    title: "Tasks",
    route: "tasks",
    icon: "FilePlusIcon",
  },
  {
    title: "Vendors",
    route: "vendors",
    icon: "FilePlusIcon",
  },


  // {
  //   header: 'T.P.R',
  //   icon: 'GitPullRequestIcon',
  //   children: [
  //     {
  //       title: 'Third Parties',
  //       route: 'thirdpartyrisks',
  //       icon: 'AlertOctagonIcon',
  //     },
  //     {
  //       title: 'Questionnaires',
  //       route: 'questionnaires',
  //       icon: 'HelpCircleIcon',
  //     },
  //     {
  //       title: 'Third Parties R.A',
  //       route: 'thirdpartyrisksRA-assessments',
  //       icon: 'AlertOctagonIcon',
  //     },
  //   ],
  // },

  // {
  //   title: "Assessments",
  //   route: "vendorassessments",
  //   icon: "GitPullRequestIcon",
  //   vendorAllow: true,
  //   onlyVendor: true,
  // },
  {
    header: "ISMS",
    icon: "RefreshCwIcon",
    children: [
      {
        title: "Periodic Activities",
        route: "periodicactivities",
        icon: "AlertOctagonIcon",
      },
    ],
  },
];

// const productionRoutes = [

//   {
//     title: 'Dashboard',
//     route: 'dashboard',
//     icon: 'PieChartIcon',
//   },
//   {
//     header: 'Frameworks',
//     icon: 'LayersIcon',
//     children: [
//       {
//         title: 'Standards',
//         route: 'standards',
//         icon: 'AwardIcon',
//       },
//     ],
//   },

//   {
//     header: 'Assessments',
//     icon: 'ClipboardIcon',
//     children: [
//       {
//         title: 'Gap Assessment',
//         route: 'gapassessment',
//         icon: 'TrendingUpIcon',
//       },
//     ],
//   },

//   {
//     title: 'Tasks',
//     route: 'tasks',
//     icon: 'FilePlusIcon',
//   },

// ]

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;
